.admin-tabs-row {
  /* display: flex; */
  justify-content: flex-end;
  padding-top: 0.5rem;
  margin-right: 0.5rem;
}
.admin-macros-inputs {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
}

.admin-macros-inputs-ispublic {
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.3rem;
}

.admin-macros-notify {
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.3rem;
}

.admin-macros-notify-btn-text {
  display: flex;
  align-items: center;
  gap: 0rem 0.3rem;
}

.admin-macros-submit-btn {
  margin-top: 0.6rem;
  background-color: #0166b3;
}

.quill-select-label {
  font-weight: 500;
  font-size: 14px;
  z-index: 2;
  position: relative;
  background-color: white;
  top: 9px;
  width: fit-content;
  left: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.quill-erase-admin {
  cursor: pointer;
  color: #0076cf;
  border-radius: 70px;
  border: none;
  background-color: initial;
  padding: 5px 9px;
}

.quill-erase-admin:hover {
  background-color: #bad8ef;
}

.macros-file {
  cursor: pointer;
  color: #0076cf;
  padding: 5px 9px;
  border-radius: 70px;
  background-color: initial;
}

.macros-file:hover {
  background-color: #bad8ef;
}

.quill-icon-buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 0.5rem;
  position: absolute;
  top: 5px;
  right: 1px;
}
